import { CloseButton, Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';
import TotpForm from '../../forms/TotpForm/TotpForm';
import styles from './styles.scoped.css';

export default function ModalOtpVerification({ open, handledClose, handleSubmit }) {
  return(
    <Modal centered className={styles.modal} opened={open} size={'lg'} withCloseButton={false} zIndex={9999}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>Verifikasi Aplikasi Authenticator</span>
          <span onClick={handledClose}><CloseButton/></span>
        </div>
        <span>
          Untuk lanjut mengakses menu <strong>Manajemen Pengkalan LPG 3 Kg</strong>,&nbsp;
          masukkan 6 digit kode verifikasi di aplikasi authenticator Anda.</span>
        <TotpForm
          buttonSubmitLabel={'Verifikasi'}
          handledClose={handledClose}
          handleSubmit={handleSubmit}
          withCancelButton={true}/>
      </div>
    </Modal>
  );
}

ModalOtpVerification.defaultProps = {
  handledClose: () => {},
  handleSubmit: () => {},
  open: false,
};

ModalOtpVerification.propTypes = {
  handledClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
};
