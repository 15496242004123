import { FAILED, LOADING, SUCCESS, RESET } from './constants';

export const initialState = {
  dataSubmitAddBusinessType: false,
  dataBusinessType : {
    data: [],
    meta: {
      page: 1,
      totalPage: 1,
      totalData: 0,
      loadedAt: new Date(),
      lastDataAt: new Date(),
    },
  },
  dataDeleteBusinessType: {},
  dataUpdateBusinessType: false,
  params: {
    page: 1,
    size: 100,
    search: '',
  },
  isLoadingUpdateBusinessType: false,
  isLoadingDeleteBusinessType: false,
  isLoadingSubmitAddBusinessType: false,
  isLoadingBusinessType: false,
  messageSubmitBusinessType: '',
  messageSubmitUpdateBusinessType: '',
  messageSubmitAddBusinessType: '',
  messageBusinessType : '',
  message: '',
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;
  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: data || initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case RESET:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
